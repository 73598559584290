export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: '', name: 'login', component: () => import('pages/auth/login/Index'), },
      { path: '/register', name: 'register', component: () => import('pages/auth/register/Index'), },
      { path: '/recover', name: 'recover', component: () => import('pages/auth/recover/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '/success', name: 'success', component: () => import('pages/success/Index'), },
      { path: '/warning', name: 'warning', component: () => import('pages/warn/Index'), },
      { path: '/error', name: 'error', component: () => import('pages/error/Index'), },
      { path: '/history', name: 'history', component: () => import('pages/history/Index'), },
      { path: '/profile', name: 'profile', component: () => import('pages/profile/Index'), },
      { path: '/profile/reset-password', backRoute: 'profile', name: 'profile.resetPassword', component: () => import('pages/profile/reset-password/Index'), },
      { path: '/checklists', name: 'checklists', component: () => import('pages/checklists/Index.vue'), },
      { path: '/checklist/:id', name: 'checklist', component: () => import('pages/checklist/Index.vue'), },
      { path: '/stats', name: 'stats', component: () => import('pages/stats/Index.vue'), },
      { path: '/stats/:id/departments', name: 'statsDepartments', component: () => import('pages/stats/departments/Index.vue'), },
      { path: '/stats/:id/departments/:depId', name: 'statsDepartment', component: () => import('pages/stats/department/Index.vue'), },
    ], },
    { path: '', component: () => import('layouts/scaner/Index'), children: [
      { path: '/index', name: 'index', component: () => import('pages/index/Index'), },
    ], },
  ], },
]
