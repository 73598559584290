import each from 'lodash/each'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import reject from 'lodash/reject'
import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import trim from 'lodash/trim'
import pull from 'lodash/pull'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import assign from 'lodash/assign'
import size from 'lodash/size'
import invert from 'lodash/invert'
import values from 'lodash/values'
import reverse from 'lodash/reverse'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import defer from 'lodash/defer'
import delay from 'lodash/delay'
import random from 'lodash/random'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import shuffle from 'lodash/shuffle'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isBoolean from 'lodash/isBoolean'


const setInRange = (value, a, b) => {
  const min = a < b ? a : b
  const max = a > b ? a : b
  
  if (value < min) return min
  if (value > max) return max
  
  return value
}

/**
 * @param {Array|Object} container
 * @param {Function} callback
 * @param {string} childrenName
 * @param {Function} parentData
 */
const eachDeep = (container, callback, childrenName = 'children', parentData = undefined) => {
  each(container, (item, key) => {
    let _parentData = parentData
    const data = (d) => {
      if (d === undefined) return _parentData
      _parentData = d
    }
    callback(item, key, data)
    const children = item[childrenName]
    if (isArray(children) || isObject(children)) {
      eachDeep(children, callback, childrenName, _parentData)
    }
  })
}

const mapDeep = (container, childrenName, callback, deep = 0) => {
  return map(container, (cont) => {
    cont = clone(cont)
    let children = cont[childrenName]
    if (isArray(children) || isObject(children)) {
      children = mapDeep(children, childrenName, callback, deep)
    }
    if (children !== undefined) {
      cont[childrenName] = children
    }
    return callback(cont)
  })
}

/**
 * @param {Array|Object} container
 * @param {Function} callback
 * @param {*} accumulator
 * @param {string} childrenName
 * @param {Function} parentData
 * @return {*}
 */
const reduceDeep = (container, callback, accumulator, childrenName = 'children', parentData = undefined) => {
  return reduce(container, (result, item, key) => {
    let _parentData = parentData
    const data = (d) => {
      if (d === undefined) return _parentData
      _parentData = d
    }
    result = callback(result, item, key, data)
    const children = item[childrenName]
    if (isArray(children) || isObject(children)) {
      result = reduceDeep(children, callback, result, childrenName, _parentData)
    }
    return result
  }, accumulator)
}

const promiseObjects = (array, key = 'promise') => {
  const promises = []
  
  array.forEach((object) => promises.push(object[key]))
  
  return Promise.all(promises).then((responses) => {
    return responses.reduce((result, response, i) => {
      result.push({
        ...array[i],
        response,
      })
      return result
    }, [])
  })
}

const isPromise = (obj) => {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'
}
import { BarcodeScanner, } from '@capacitor-community/barcode-scanner'

const startScan = async () => {
  await BarcodeScanner.checkPermission({ force: true, })

  await BarcodeScanner.hideBackground()
  document.querySelector('body').classList.add('scanner-active')

  const result = await BarcodeScanner.startScan()

  if (result.hasContent) {
    return result.content
  } else {
    return ''
  }
}


import { Geolocation, } from '@capacitor/geolocation'
import {Device} from '@capacitor/device';

const getImei = async () => {
  const info = await Device.getId()

  return info.identifier
}



// const currentGeo = async () => {
//   if (Capacitor.isPluginAvailable('Geolocation')) {
//     const { HMSLocation } = Device;
//     try {
//       const locationResult = await HMSLocation.getCurrentLocation();
//       if (locationResult && locationResult.locations.length > 0) {
//         const location = locationResult.locations[0];
//         const coordinates = {
//           latitude: location.latitude,
//           longitude: location.longitude,
//           accuracy: location.accuracy,
//         };
//         console.log('Current location (Huawei):', coordinates);
//         return coordinates;
//       } else {
//         alert('Не удалось получить координаты с помощью Huawei Location Kit');
//         return null;
//       }
//     } catch (error) {
//       alert('Ошибка при получении местоположения с помощью Huawei Location Kit: ' + error);
//       return null;
//     }
//   } else {
//     try {
//       const permits = await Geolocation.checkPermissions();
//       if (permits.location === 'denied' && permits.coarseLocation === 'denied') {
//         alert('Разрешите доступ к геоданным');
//         return false;
//       }
//       const position = await Geolocation.getCurrentPosition();
//       const coordinates = {
//         latitude: position.coords.latitude,
//         longitude: position.coords.longitude,
//         accuracy: position.coords.accuracy,
//       };
//       console.log('Current location (non-Huawei):', coordinates);
//       return coordinates;
//     } catch (error) {
//       /// здесь должен быть алгоритм по huawei
//       alert('Ошибка при получении местоположения: ' + error);
//       return null;
//     }
//   }
// };



const currentGeo = async () => {
  const permits = await Geolocation.checkPermissions()
  if (permits.location === 'denied' && permits.coarseLocation === 'denied') {
    alert('Разрешите доступ к геоданным')
    return false
  }
  const coordinates = await Geolocation.getCurrentPosition()
  return coordinates
}

// const currentGeo = async () => {
//   const info = await Device.getInfo();
//   const isHuaweiDevice = info.model.toLowerCase().includes('huawei');
//
//   if (isHuaweiDevice && Capacitor.isPluginAvailable('HMSLocation')) {
//     alert('aboba');
//   } else {
//     const permits = await Geolocation.checkPermissions();
//     if (permits.location === 'denied' && permits.coarseLocation === 'denied') {
//       alert('Разрешите доступ к геоданным');
//       return false;
//     }
//     const coordinates = await Geolocation.getCurrentPosition();
//     console.log('Current location (non-Huawei):', coordinates);
//     return coordinates;
//   }
// };

// const currentGeo = async () => {
//   if (Capacitor.isPluginAvailable('HMSLocation')) {
//     // // Если плагин HMS Location доступен, используем его для устройств Huawei
//     // const hmsLocation = window.hmsLocation;
//     // const locationRequest = {
//     //   priority: hmsLocation.PriorityConstants.PRIORITY_HIGH_ACCURACY,
//     //   interval: 5000,
//     //   numUpdates: 1,
//     //   fastestInterval: 2000,
//     //   expirationTime: 60000
//     // };
//     //
//     // try {
//     //   const location = await hmsLocation.getFusedLocationProviderClient().getLastLocation(locationRequest);
//     //   console.log('Current location (Huawei):', location);
//     //   return location;
//     // } catch (error) {
//     //   console.error('Error getting location (Huawei):', error);
//     //   return false;
//     // }
//     alert('aboba')
//   } else {
//     // Иначе используем стандартный метод Capacitor для других устройств
//     const permits = await Geolocation.checkPermissions();
//     if (permits.location === 'denied' && permits.coarseLocation === 'denied') {
//       alert('Разрешите доступ к геоданным');
//       return false;
//     }
//     const coordinates = await Geolocation.getCurrentPosition();
//     console.log('Current location (non-Huawei):', coordinates);
//     return coordinates;
//   }
// }

const getMessage = (messages, field = null) => {
  if (field === null) {
    return messages.message
  }
  messages = messages.data
  if (messages[field]) {
    if (isArray(messages[field]) && messages[field].length !== 0) {
      return messages[field][0]
    } else {
      return messages[field]
    }
  } else {
    return ''
  }
}


const notifyAvailable = () => {
  return Capacitor.isPluginAvailable('PushNotifications')
}



export default () => ({
  each,
  map,
  reduce,
  find,
  filter,
  reject,
  merge,
  get,
  set,
  unset,
  trim,
  pull,
  includes,
  concat,
  assign,
  size,
  invert,
  values,
  reverse,
  clone,
  cloneDeep,
  defer,
  delay,
  random,
  sortBy,
  shuffle,
  debounce,
  throttle,
  isEqual,
  isObject,
  isArray,
  isFunction,
  isString,
  isBoolean,
  
  setInRange,
  mapDeep,
  reduceDeep,
  eachDeep,
  isPromise,
  promiseObjects,

  startScan,
  currentGeo,
  getMessage,
  notifyAvailable,
  getImei,
})
