export default {
  app: {
    state: {
      buffer: [],
    },
    getters: {
      buffer(state) {
        return state.buffer
      },
    },
    mutations: {
      buffer(state, value) {
        state.buffer.push(value)
      },
    },
    namespaced: true,
    actions: {
      setBuffer({ state, commit, }, value) {
        value = {
          ...value,
          createdAt: $app.date.format(new Date(), 'datetime'),
        }
        commit('buffer', value)
      },
      sendBuffer({ state, commit, }) {
        return $api.visits.offline($app.auth.user().companyId, {
          visits: state.buffer,
        }).then((response) => {
          state.buffer = []
        })
      },
      updateUser() {
        return $api.auth.info().with('branches').with('company').with('department').then((response) => {
          $app.auth.user(response.data.content.user)
        })
      },
    },
  },
}
